import * as React from 'react'
import { graphql } from 'gatsby'
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from 'gatsby-source-storyblok'

import { createContactFormBlok, createUpcomingEventsBlok } from '../utils'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const DynamicPage = ({ pageContext, location }) => {
  let story = pageContext?.story
  story = useStoryblokState(story)

  const contactFormSectionBlok = createContactFormBlok({
    context: pageContext,
  })

  const components =
    story?.content?.body?.map((blok, index) => {
      let finalBlok = {
        ...blok,
        ...(blok.component === 'ContactFormSection'
          ? {
              contactForm: {
                ...contactFormSectionBlok,
                variant: 'standalone',
              },
            }
          : undefined),
        ...(blok.component === 'UpcomingEvents'
          ? {
              ...createUpcomingEventsBlok({ context: pageContext }),
            }
          : undefined),
        sectionIndex: index,
        isFirstSection: index === 0,
      }

      return <StoryblokComponent blok={finalBlok} key={finalBlok._uid} />
    }) || []

  return (
    <Layout
      context={pageContext}
      location={location}
      floatingSiteNavigation={story?.content?.floatingSiteNavigation}
    >
      <main {...storyblokEditable(story?.content)}>{components}</main>
    </Layout>
  )
}

export default DynamicPage

export const Head = ({ pageContext, location }) => {
  const storyContent = pageContext?.story?.content || {}

  return (
    <Seo
      location={location}
      seo={storyContent.seo}
      noindex={storyContent.noindex}
      ignorePageTitleTemplate={storyContent.ignorePageTitleTemplate}
      siteConfig={pageContext?.siteConfig}
    />
  )
}

export const query = graphql`
  query ($langCode: String!) {
    locales: allLocale(filter: { language: { eq: $langCode } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
